<template>
    <md-table-row :id="'group-row-' + group.prio">
        <md-table-cell :id="'group-row-prio-' + group.prio"  md-sort-by="prio" md-label="Prio"><div>#{{group.prio}}</div></md-table-cell>

        <md-table-cell :id="'group-row-name-' + group.prio" class="name-cell" md-sort-by="name" md-label="Name">
            <div>
                <vue-flag :code="group.by" size='small'/>
                {{group.name}}

                <md-tooltip md-direction="top" class="tool-tip-multi-row">
                    <span >
                        <p>
                            • Last update : {{TimeUtils.formatTimestampTo(group.lastUpdateDate)}}<br />
                            • By : {{group.lastUpdateUser}}
                        </p>
                    </span>
                </md-tooltip>

            </div>
        </md-table-cell>

        <md-table-cell :id="'group-row-version-' + group.prio" md-sort-by="usedVersion" md-label="Version">
            <div>
                {{group.usedVersionName}}
            </div>
        </md-table-cell>

        <md-table-cell :id="'group-row-method-' + group.prio" md-sort-by="availability" md-label="Method">
            <div>
                {{group.availability}}
            </div>
        </md-table-cell>

        <md-table-cell :id="'group-row-deployment-' + group.prio"
                       v-if="groupState != undefined || statError !== null"
                       class="font-family-cell"
                       md-label="Deployment"
        >

            <div v-if="statError !== null">
                <span class="deployment-error-cell">{{ error }}</span>
            </div>
            <div v-else class="font-family-cell">
                <span>Countries : {{groupState.country}}</span><br />
                <span>Sites : {{groupState.site}}</span><br />
                <span>Devices : {{groupState.total}}</span>
            </div>
        </md-table-cell>
        <md-table-cell :id="'group-row-deployment-loading-' + group.prio" v-else class="font-family-cell" md-label="Deployment">
            <div v-if="!group.activated">{{TOOLTIPS.INACTIVE_GROUP}}</div>
            <div v-else class="skeleton-cell"><v-skeleton-loader type="text@3" /></div>
        </md-table-cell>

        <md-table-cell :id="'group-row-up-to-date-' + group.prio" v-if="(groupState != undefined && group.activated) || statError !== null"
                       md-label="Up to Date" class="table-unset-hidden up-to-date-cell">
            <div v-if="statError !== null" class="deployment-error-cell">
                <span >{{ error }}</span>
            </div>
            <up-to-date-column v-else
                               :groupId="group.id"
                               :state="groupState"
                               :advanced-stats="advancedStats"
            />
        </md-table-cell>
        <md-table-cell :id="'group-row-up-to-date-loading-' + group.prio" v-else class="font-family-cell" md-label="Up to Date">
            <div v-if="!group.activated">{{TOOLTIPS.INACTIVE_GROUP}}</div>
            <div v-else class="skeleton-cell"><v-skeleton-loader type="text@3" /></div>
        </md-table-cell>

        <md-table-cell :id="'group-row-enabled-' + group.prio" md-label="Enabled">
            <filter-toggle :status='group.activated'></filter-toggle>
        </md-table-cell>

        <md-table-cell :id="'group-row-playstore-' + group.prio"
                       md-sort-by="androidTrackId"
                       md-label="Playstore">

            <group-android-track-button ref="groupAndroidTrackButtonRef" v-if="app.uid"
                                        :android-track="androidTrack"
                                        :app-version-code="group.appVersionCode"
                                        :app-uid="app.uid"
                                        :app-type="app.appType"
                                        :app-id="app.id"
            />

        </md-table-cell>

        <md-table-cell :id="'group-row-status-' + group.prio" md-sort-by="status" md-label="Status">
            <div>
                <span v-if="group.status === CONSTANT.STATUS_CALC_GROUP.TODO"><md-icon>flag</md-icon><md-tooltip
                    md-direction="top">{{tooltipStatusToDo}}</md-tooltip></span>
                <span v-if="group.status === CONSTANT.STATUS_CALC_GROUP.DONE"><md-icon>done</md-icon><md-tooltip
                    md-direction="top">{{tooltipStatusDone}}</md-tooltip></span>
                <span v-if="group.status === CONSTANT.STATUS_CALC_GROUP.FAILED || group.status === CONSTANT.STATUS_CALC_GROUP.FAILED_INSERT"><md-icon class="danger">clear</md-icon><md-tooltip
                    md-direction="top">{{tooltipStatusFailed}}</md-tooltip></span>
                <span v-if="group.status === CONSTANT.STATUS_CALC_GROUP.FAILED_NOTIF"><md-icon class="danger">unsubscribe</md-icon><md-tooltip
                    md-direction="top">{{tooltipStatusFailedNotification}}</md-tooltip></span>
                <v-progress-linear v-else-if="group.status === CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS || group.status === CONSTANT.STATUS_CALC_GROUP.NOTIFYING"
                                   :value="progressBarValueComputed"
                                   :color="chooseProgressBarColor()">
                    <md-tooltip
                        md-direction="top">{{ chooseProgressBarTooltip() }}</md-tooltip>
                </v-progress-linear>
            </div>
        </md-table-cell>

        <md-table-cell :id="'group-row-action-' + group.prio" md-label="Action" class="reduce-padding defaultCell">
            <v-layout row wrap justify-center>
                <v-flex xs4 sm4>
                    <mdm-button :id="'applications-details-btn-edit-group-' + group.prio" :css="'md-dense md-icon-button'"
                                :tooltip-text="(group.status !== CONSTANT.STATUS_CALC_GROUP.DONE && group.status !== CONSTANT.STATUS_CALC_GROUP.FAILED) ? tooltipModifyProcessingGroup : countGroup > addGroupMaxLimit ? tooltipAddGroupMaxLimit + addGroupMaxLimit :tooltipModifyGroup"
                                :icon="'edit'"
                                :disable="(group.status === CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS) || grant('application','medium', group['countries']) === 'button-disable' || countGroup > addGroupMaxLimit"
                                :to="{path: $route.params.id + '/group/' + group.id}"/>
                </v-flex>

                <v-flex xs4 sm4>
                    <mdm-button :id="'applications-details-btn-delete-group-' + group.prio"
                                :css="'md-icon-button md-dense md-accent'"
                                :tooltip-text="(cannotDeleteGroup(group)) ? tooltipModifyProcessingGroup : tooltipDeleteGroup"
                                :icon="'delete'"
                                :disable="(cannotDeleteGroup(group)) || grant('application','medium',group['countries']) === 'button-disable'"
                                @click="$emit('deleteGroupPopin', 'deleteGroup', group.id)"/>
                </v-flex>
            </v-layout>
        </md-table-cell>
    </md-table-row>
</template>

<script>
import GroupAndroidTrackButton from "../../Group/GroupAndroidTrackButton/groupAndroidTrackButton";
import FieldConstant from "../../../utils/field-constant";
import UpToDateColumn from "../../../pages/applications/details/group/up-to-date-column";
import groupService from "../../../service/group/group-service";
import TimeUtils from '../../../utils/time-utils';
import constantsMigration from "../../../pages/migration/constants-migration";
import Vue from "vue";
import appTypeEnum from "../../../service/application/app-type-enum";
export default {
    name: "tableRow",
    components: {
        GroupAndroidTrackButton,
        UpToDateColumn
    },
    props : {
        group : {
            type: Object,
            required: true
        },
        app : {
            type: Object
        },
        androidTrack : {
            type: Object
        },
        grant : {
            type : Function
        },
        state : {
            type : Object
        },
        error: {
            type: String | null
        }
    },
    data() {
        return {
            TimeUtils,
            CONSTANT: FieldConstant,
            constants: constantsMigration,
            tooltipStatusToDo: this.$store.state.labels.STATUS_TODO,
            tooltipStatusInProgressPrivateApp: this.$store.state.labels.STATUS_IN_PROGRESS_PRIVATE_APP,
            tooltipStatusInProgressNotPrivateApp : this.$store.state.labels.STATUS_IN_PROGRESS_NOT_PRIVATE_APP,
            tooltipStatusNotifyingAfterPlaystoreUpdate: this.$store.state.labels.CONFIGURATION_BEING_APPLIED_PRIVATE_APP,
            tooltipStatusNotifyingAfterGroupSavePrivateApp: this.$store.state.labels.CHECKING_DEVICES_AND_VERSIONS_PRIVATE_APP,
            tooltipStatusNotifyingAfterGroupSaveNotPrivateApp: this.$store.state.labels.CONFIGURATION_BEING_APPLIED_NOT_PRIVATE_APP,
            tooltipStatusDone: this.$store.state.labels.STATUS_DONE,
            tooltipStatusFailed: this.$store.state.labels.STATUS_FAILED,
            tooltipStatusFailedNotification: this.$store.state.labels.FAILED_NOTIFICATION,
            tooltipModifyProcessingGroup: this.$store.state.labels.GROUP_MODIFY_PROCESSING_IMPOSSIBLE,
            tooltipAddGroupMaxLimit: this.$store.state.labels.GROUP_ADD_IMPOSSIBLE_MAX_LIMIT,
            tooltipModifyGroup: this.$store.state.labels.GROUP_MODIFY_IMPOSSIBLE,
            tooltipDeleteGroup: this.$store.state.labels.GROUP_DELETE_IMPOSSIBLE,
            addGroupMaxLimit: 10,
            TOOLTIPS: FieldConstant.APPLICATION.TOOLTIPS,
            advancedStats : {
                error: null,
                data: null
            },
            GROUPS_AND_TRACKS_REFRESH_DELAY: 1000,
            progressBarValueInProgress: 0,
            progressBarValueNotifyingAfterPlaystoreUpdate: 0,
            progressBarValueNotifyingAfterGroupSave: 0,
            groupProgressionStats: {}

        }
    },
    computed : {
        groupState: function () {
            return this.state;
        },
        countGroup() {
            return this.$store.state.group.group.countGroup;
        },
        statError() {
            return this.error;
        },
        progressBarValueComputed() {
            if (this.group.status === this.CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS) {
                return this.progressBarValueInProgress;
            } else if (this.group.status === this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats?.body?.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_GROUP_SAVE) {
                return this.progressBarValueNotifyingAfterGroupSave;
            } else if (this.group.status === this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats?.body?.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_PLAYSTORE_UPDATE) {
                return this.progressBarValueNotifyingAfterPlaystoreUpdate;
            }
        }
    },
    methods : {
        cannotDeleteGroup(group) {
            return !group ? true : FieldConstant.STATUS_CALC_GROUP.CAN_T_DELETE_GROUP.includes(group.status);
        },
        async loadAdvancedStats() {
            try {
                this.advancedStats.data = await groupService.getAdvancedStats(this.group.id);
                const totalGroupTargetedDevices = this.advancedStats.data.updateSince.threeMonths.total.all;
                const totalGroupDevicesUpToDate = this.advancedStats.data.updateSince.threeMonths.upToDate.all;
                this.$store.commit('application/addDevicesUpToDate', totalGroupDevicesUpToDate);
                this.$store.commit('application/addTotalTargetedDevices', totalGroupTargetedDevices);
                this.$store.commit('application/setUpToDateDevicesByVersionName', {... this.advancedStats.data, versionName: this.group.usedVersionName});

            } catch(e) {
                console.error('Error loading advanced stats for group ' + this.group.id, e);
                this.advancedStats.error = true;
            }
        },
        async getProgressBarValue(groupId) {
            this.groupProgressionStats = await Vue.http.get(this.$store.state.getUrlWithParams(this.$store.state.urlDeploymentProgressionStatus, { groupId }));

            if (this.group.status === this.CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS) {
                this.progressBarValueInProgress = Math.round((this.groupProgressionStats.body.number / this.groupProgressionStats.body.total) * 100);
            } else if (this.group.status === this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats.body.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_GROUP_SAVE) {
                this.progressBarValueNotifyingAfterGroupSave = Math.round((this.groupProgressionStats.body.number / this.groupProgressionStats.body.total) * 100);
            } else if (this.group.status === this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats.body.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_PLAYSTORE_UPDATE) {
                this.progressBarValueNotifyingAfterPlaystoreUpdate = Math.round((this.groupProgressionStats.body.number / this.groupProgressionStats.body.total) * 100);
            }
        },
        chooseProgressBarColor() {
            if(this.group.status === this.CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS) {
                return this.constants.COLORS.BLUE_BRAND;
            } else if (this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats?.body?.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_GROUP_SAVE) {
                return this.app.appType === appTypeEnum.PRIVATE_APP ? this.constants.COLORS.ORANGE_PLAYSTORE_LOGO : this.constants.COLORS.GREEN_PLAYSTORE_LOGO;
            } else if (this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats?.body?.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_PLAYSTORE_UPDATE) {
                return this.constants.COLORS.GREEN_PLAYSTORE_LOGO;
            }
        },
        chooseProgressBarTooltip() {
            if(this.group.status === this.CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS) {
                return this.app.appType === appTypeEnum.PRIVATE_APP ? this.tooltipStatusInProgressPrivateApp : this.tooltipStatusInProgressNotPrivateApp ;
            } else if (this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats?.body?.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_GROUP_SAVE) {
                return this.app.appType === appTypeEnum.PRIVATE_APP ? this.tooltipStatusNotifyingAfterGroupSavePrivateApp : this.tooltipStatusNotifyingAfterGroupSaveNotPrivateApp;
            } else if (this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING && this.groupProgressionStats?.body?.state === this.CONSTANT.DEPLOYMENT_STATUS_PROGRESSION.NOTIFYING_AFTER_PLAYSTORE_UPDATE) {
                return this.tooltipStatusNotifyingAfterPlaystoreUpdate;
            }
        },
    },
    async mounted() {
        await this.loadAdvancedStats();
        this.statusInterval = setInterval(async () => {
            if(this.group.status === this.CONSTANT.STATUS_CALC_GROUP.TODO
                || this.group.status === this.CONSTANT.STATUS_CALC_GROUP.IN_PROGRESS
                || this.group.status === this.CONSTANT.STATUS_CALC_GROUP.NOTIFYING) {
                this.group.status = (await groupService.getGroup(this.group.id, false))?.status;
                await this.getProgressBarValue(this.group.id)
            } else {
                clearInterval(this.statusInterval);
            }
        }, this.GROUPS_AND_TRACKS_REFRESH_DELAY);
    }
};
</script>
<style lang="scss" scoped>
td {
    font-size : 11px;
    vertical-align: middle;
    text-align: center;
    width: max-content;
    &.name-cell {
        max-width: 120px;
        word-wrap: break-word;
    }
    &.font-family-cell {
        font-family: 'Roboto', sans-serif;
    }
    &.skeleton-cell {
        width:100%;
    }
    &.up-to-date-cell {
        font-size: 13px;
        font-weight: bold;
    }
}
.deployment-error-cell {
    color : var(--vtmn-color_danger);
    font-size: 13px;
    font-weight: bold;
}

</style>
