/* VUEX */
import Vue from "vue";
import Vuex from "vuex";
import {messageConstant} from "./utils/message-constant.js";
import UrlConstant from './constant/url-constant';
import storeModules from "./store/index";
import HttpRequestsUtils from './utils/http-requests-utils.js';

Vue.use(Vuex);

var staging = (process.env.VUE_APP_ENV || "").startsWith('prod') ? "prod" : process.env.VUE_APP_ENV;

export function getStore(url) {
    let store;
    const urlAPIPortal = process.env.VUE_APP_API_PORTAL_URL_EMM;
    const urlKioskRealtime = process.env.VUE_APP_URL_SOCKET_IO;

    const urlAPI = !url.includes("localhost") ? urlAPIPortal + "/mdmold/" : url + "/decathlon-mdm-server-mvc/";

    store = new Vuex.Store({
        state: {
            // ###### PARAMETERS ######
            staging: staging,
            labels: messageConstant,

            // ###### API KEY ######
            apiKeyMdmApiPortal: process.env.VUE_APP_API_PORTAL_KEY,

            // ###### URL ######
            urlAWSAppRepo: urlAPIPortal + "/application-api/public-repository/apks/${uid}",
            urlDownloadVersion: `${urlAPIPortal}/apkurl/url/retail/{country}/{store}/{device}/{base64_file_path}?source=mdm-front`,
            urlAdminRate: urlAPIPortal + "/admin/graphql/rating",
            urlInfosKiosk: process.env.VUE_APP_URL_DEVICES_API + "/kiosks/${idDevice}",
            urlKiosksFirmwareUpdatePreApply: process.env.VUE_APP_URL_DEVICES_API + "/kiosks/firmware-update/pre-apply",
            urlAWSUpdateInfosKiosk: urlAPIPortal + "/kioskInfo/kiosks",
            urlKioskPulling: urlKioskRealtime,
            urlKioskConnected: urlAPIPortal + "/kiosks/K${idDevice}/connected",
            urlAWSS3MobileRepo: "https://s3-eu-west-1.amazonaws.com/com.decathlon.mobilerepo/${fileName}",


            // ------- USERS       -------
            urlApiPortalUserFromGoogleApis: process.env.VUE_APP_URL_ADMIN + "/user-info/google/${userEmail}?b=",
            urlApiPortalDirectoryUsers: process.env.VUE_APP_URL_ADMIN + "/directory-api",

            // ------- APPLICATION -------
            urlApplicationApiCheckS3Availability: process.env.VUE_APP_URL_APPLICATION_API + "/applications/check-s3-availability",
            urlApplicationIdOrPackageName: UrlConstant.urlApplicationIdOrPackageName,
            urlRetryCompletePrio: urlAPI + "api/v2/front/group/retry/${idApplication}",
            urlApplicationIdGroups: urlAPI + "api/v2/front/application/${idApplication}/groups",
            urlGroupId: process.env.VUE_APP_URL_APPLICATION_API + "/groups/${idGroup}",
            urlGroupDeviceCount: process.env.VUE_APP_URL_APPLICATION_API + "/groups/devices/_search",
            urlGroupSave: process.env.VUE_APP_URL_APPLICATION_API + "/groups",
            urlNotifyAllAndroidPoliciesForGroupId: process.env.VUE_APP_URL_APPLICATION_API + "/groups/${groupId}/notify",
            urlVersionId: urlAPI + "api/v2/front/version/${idVersion}",
            urlUploadVersionFile: process.env.VUE_APP_URL_APPLICATION_API + "/applications/version/upload",
            urlVersionSaved: urlAPI + "api/v2/front/version/saved",
            urlApplications: process.env.VUE_APP_URL_APPLICATION_API + "/applications/_search",
            urlWebviewsSearch: process.env.VUE_APP_URL_AAM_API + "/webview/private/_search",
            urlAppsIntalledAndWaitingToInstall: process.env.VUE_APP_URL_APPLICATION_API + "/application/device/installed/${uid}",

            urlGroupDelete: urlAPIPortal + "/application-api/groups/${idGroup}",
            urlApplicationUpdate: process.env.VUE_APP_URL_APPLICATION_API + "/application/update",
            urlApplicationExist: urlAPI + "api/v2/front/application/exist/${packageName}",
            urlApplicationCreate: process.env.VUE_APP_URL_APPLICATION_API + "/application/create",
            urlGroupState: process.env.VUE_APP_URL_APPLICATION_API + "/groups/${groupId}/stats",
            urlGroupAdvancedState: process.env.VUE_APP_URL_APPLICATION_API + "/groups/${groupId}/stats/details",
            urlDeviceRegister: urlAPI + "api/v2/mdm/device/register",
            urlGroupPrio: process.env.VUE_APP_URL_APPLICATION_API + "/applications/${applicationId}/groups/priorities",
            urlPlaystoreReady: process.env.VUE_APP_URL_APPLICATION_API + "/application/${packageName}/playstoreready",
            urlApplicationForStore: process.env.VUE_APP_URL_APPLICATION_API + "/applications/countries/${countryCode}/stores/${storeId}",
            urlApplicationsByDeviceType: process.env.VUE_APP_URL_APPLICATION_API + "/applications/device-type/${deviceType}",
            urlAppApiPlaystoreEventsLastGroupsStatus: process.env.VUE_APP_URL_APPLICATION_API + "/mdm-event-status/state/${appId}/${appVersionCode}",
            urlAppApiPlaystoreEventsLastAppStatus: process.env.VUE_APP_URL_APPLICATION_API + "/mdm-event-status/state/${appId}",
            urlApplicationByPackageName: process.env.VUE_APP_URL_APPLICATION_API + "/applications/_search",
            getSpecificApplication: process.env.VUE_APP_URL_AAM_API + "/applications/country/${country}/store/${store}",
            urlPrivateApplicationIcon: process.env.VUE_APP_URL_APPLICATION_API + "/applications/${packageName}/icon",
            urlApplicationIcon: process.env.VUE_APP_URL_APPLICATION_API + "/applications/${appId}/icon",
            urlApplicationIconsByPackageNames: process.env.VUE_APP_URL_APPLICATION_API + "/applications/icon",
            urlApplicationVersionCode: process.env.VUE_APP_URL_AAM_API + "/applications/${packageName}/productionVersionCode",
            urlDeploymentProgressionStatus: process.env.VUE_APP_URL_APPLICATION_API + "/groups/deployment-progression-status/${groupId}",

            // ------- DEVICES -------
            urlDevicesModels: process.env.VUE_APP_URL_DEVICES_API + "/devices/models",
            urlDevicesApiSearch: process.env.VUE_APP_URL_DEVICES_API + "/devices/_search",
            urlDevicesApiExport: process.env.VUE_APP_URL_DEVICES_API + "/devices/_export",
            urlDevicesOsVersions: process.env.VUE_APP_URL_DEVICES_API + "/devices/${os}/os_versions",
            urlCountStatDevices: process.env.VUE_APP_URL_DEVICES_API + "/devices/count",
            urlNotifyAamKiosks: process.env.VUE_APP_URL_DEVICES_API + "/aamKiosks/notify",
            urlDsRemoteFunctions: process.env.VUE_APP_URL_DEVICES_API + "/dsRemoteFunction/${action}",
            urlDeviceDeactivate: process.env.VUE_APP_URL_DEVICES_API + "/devices/deactivate",
            urlDevicesSearch: urlAPIPortal + "/devices",
            urlDeviceManufacturers: urlAPI + "api/v2/front/device/manufacturers",
            urlDeviceId: process.env.VUE_APP_URL_DEVICES_API + "/device/${idDevice}",
            urlDeviceIdApps: process.env.VUE_APP_URL_DEVICES_API + "/device/${idDevice}/apps",
            urlDeviceIdUpdateOverriddenPolicy: process.env.VUE_APP_URL_DEVICES_API + "/device/${idDevice}/custom_mode",
            urlDeviceUpdate: process.env.VUE_APP_URL_DEVICES_API + "/device/${idDevice}",
            urlDeviceApiDeleteById: process.env.VUE_APP_URL_DEVICES_API + "/device/${idDevice}",
            urlArbo: process.env.VUE_APP_URL_DEVICES_API + "/arbo",
            urlArboWithDevices: process.env.VUE_APP_URL_DEVICES_API + "/arbo?includeDevices=true",
            urlDeviceLocalization: urlAPI + "api/v2/front/device/${idDevice}/localization",
            urlDeviceBlock: urlAPI + "api/v2/front/devices/block",
            urlForceUpdateApplicationsPolicies: process.env.VUE_APP_URL_DEVICES_API + "/devices/${idDevice}/force-update",
            urlCacheCheck: process.env.VUE_APP_URL_ADMIN + "/cache-check/${key}/${value}",


            // ------- USER -------
            urlUser: urlAPI + "api/v2/front/user",
            urlUserAdmin: process.env.VUE_APP_URL_ADMIN + "/user",
            user: {
                "uid": "fake"
            },

            gdpr: false,

            // ------- HOMEPAGE -------
            urlHomepageTraceS3: process.env.VUE_APP_URL_ADMIN + "/s3-data/trace_events",
            urlHomepageState: urlAPI + "api/v2/front/group/device/state",
            urlHomepageEnrollment: process.env.VUE_APP_URL_DEVICES_API + "/devices/enrolments",

            // ------- FOOTER -------
            urlBackendVersion: urlAPI + "api/v2/front/version/backend",

            // ------- ANDROID API -------
            urlAndroidApiActiveUser: process.env.VUE_APP_URL_AAM_API + "/active/user",
            urlEnrollment: process.env.VUE_APP_URL_AAM_API + "/enrollment",
            urlAndroidApiApplicationsTracks: process.env.VUE_APP_URL_AAM_API + "/applications/${packageName}/tracks",
            urlAndroidApiDevice: process.env.VUE_APP_URL_AAM_API + "/device/${deviceName}",
            urlIframeGetToken: process.env.VUE_APP_URL_AAM_API + "/iframe/token",
            urlGetPublicApplicationInfo: process.env.VUE_APP_URL_AAM_API + "/application/public/${packageName}",
            urlGetWebviewInfo: process.env.VUE_APP_URL_AAM_API + "/webview/private/${packageName}",

            //  ------- HEADER INFORMATION ------
            urlHeaderInformation: process.env.VUE_APP_URL_ADMIN + "/header",
            getUrlWithParams: HttpRequestsUtils.getUrlWithParams,

            //  ------- FIRMWARE ------
            urlGetAdminAllFirmwaresWithRecalculatedPerimeter: process.env.VUE_APP_URL_ADMIN + "/elo-i-series-4-firmware?recalculatePerimeter=true",
            urlGetAdminAllFirmwaresKioskPolicyUpdate: process.env.VUE_APP_URL_ADMIN + "/elo-i-series-4-firmware/policy-update/stats",
            urlGetAdminAllFirmwaresDeploymentStats: process.env.VUE_APP_URL_ADMIN + "/elo-i-series-4-firmware/deployment/stats",
            urlCreateUpdateDeleteFirmwares: process.env.VUE_APP_URL_ADMIN + "/elo-i-series-4-firmwares",

            // ------- MIGRATION ------
            urlMigrationDeviceSearch: process.env.VUE_APP_URL_EMM_MIGRATION_API + "/devices/_search",
            urlMigrationMetrics: process.env.VUE_APP_URL_EMM_MIGRATION_API + "/devices/migrate_count",
            urlMigrationDevicesExport: process.env.VUE_APP_URL_EMM_MIGRATION_API + "/devices/_export",
            urlGetAdminParameter: process.env.VUE_APP_URL_ADMIN + "/params/"
        },
        mutations: {
            increment(state) {
                state.test++;
            },
            setUser(state, user) {
                state.user = user;
            },
            setGdprAlreadySeen(state, seen) {
                state.gdpr = seen;
            }
        },
        modules: storeModules,
    });

    return store;
}
